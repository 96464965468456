import { UtilitiesInstance, ProfileTSInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

export const state = () => ({
  timezones: null,
  roles: null,
  pref: ProfileTSInstance.me.getPreferences()
})

export const getters = {
  timezones (state) {
    return state.timezones ? state.timezones : false
  },
  roles (state) {
    return state.roles ? state.roles : false
  },
  pref (state) {
    return state.pref
  }
}

export const mutations = {
  setTimezones (state, timezones) {
    state.timezones = timezones
  },
  setRoles (state, roles) {
    state.roles = roles
  },
  setPref (state, pref) {
    state.pref = Object.assign(state.pref, pref)
  }
}

export const actions = {
  GET_TIMEZONES (context) {
    return UtilitiesInstance.commons
      .getTimezones()
      .then(res => {
        context.commit('setTimezones', res)
        return Promise.resolve(res)
      })
      .catch(err => catchError(err, context))
  },
  GET_ROLES (context) {
    return UtilitiesInstance.commons
      .getRoles()
      .then(res => {
        context.dispatch('MANAGE_ROLES', res)
        return Promise.resolve(res)
      })
      .catch(err => catchError(err, context))
  },
  MANAGE_ROLES (context, res) {
    if (context.getters.componentIsEnabled('roles', 'readOnly')) {
      res = res.filter(item => {
        if (item.value.toLowerCase() === 'observer') item.value = 'Read Only'
        if (['manager', 'analyst', 'observer'].includes(item.key.toLowerCase()))
          return item
      })
    }
    context.commit('setRoles', res)
  },
  GET_PREFERENCES (context) {
    const pref = ProfileTSInstance.me.getPreferences()
    context.commit('setPref', pref)
    return pref
  },
  SET_LANGUAGE (context, lang) {
    context.commit('toggleGlobalLoading', true)
    const preferences = Object.assign({}, context.getters.pref)
    preferences.language = lang
    context.dispatch('SET_PREFERENCES', preferences)
  },
  SET_THEME_MODE (context, mode) {
    const preferences = Object.assign({}, context.getters.pref)
    preferences.appearance.mode = mode
    context.dispatch('SET_PREFERENCES', preferences)
  },
  SET_PREFERENCES (context, preferences) {
    try {
      preferences.appearance.theme = process.env.VUE_APP_THEME
      ProfileTSInstance.me.setPreferences(preferences)
      if (context.getters.pref.language !== preferences.language)
        location.reload()
      context.commit('setPref', preferences)
      return true
    } catch (err) {
      catchError(err, context)
    }
  },
  GET_ADDRESS_BY_ZIPCODE ({ commit }, params = {}) {
    return UtilitiesInstance.commons
      .getAddressByZipCode(params.zipCode)
      .then(res => Promise.resolve(res))
      .catch(err => Promise.reject(err))
  },
  FORCE_LIGHT_MODE ({ state, dispatch }) {
    const preferences = Object.assign({}, state.pref)
    preferences.appearance.mode = 'light'
    dispatch('SET_PREFERENCES', preferences)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
