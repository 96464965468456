import { isEmptyObject } from '@/support/helpers.js'
import store from '@/store'
import {
  merchantStaffGetEnabledAndIsNotStaff,
  merchantStaffSearchEnabledAndIsNotStaff
} from '@/actions/merchant-staff.actions'
import {
  invitesSearchEnabledAndIsNotStaff,
  invitesCreateEnabledAndIsNotStaff,
  invitesGetEnabledAndIsNotStaff
} from '@/actions/merchant-staff-invites.actions'

const routeStaff = {
  path: 'staff/',
  component: () =>
    import(/* webpackChunkName: "accessList" */ '@/views/Users/AccessList.vue'),
  props: {
    tabbarItems: [
      {
        route: 'staffUser'
      },
      {
        route: 'staffInvite'
      }
    ]
  },
  children: [
    {
      path: '',
      redirect: { name: 'staffUser' }
    },
    {
      path: 'user/',
      name: 'staffUser',
      beforeEnter: (to, from, next) => {
        const query = isEmptyObject(to.query) ? { page: 1 } : to.query
        query.merchantId = store.getters.merchantActive.id

        if (
          !merchantStaffSearchEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        store.dispatch('GET_TEAM_USERS', query)
        next()
      },
      props: {
        actionName: 'GET_TEAM_USERS',
        getterName: 'teamUsers',
        inviteRoute: 'staffInviteCreate'
      },
      component: () =>
        import(/* webpackChunkName: "userList" */ '@/views/Users/UserList.vue')
    },
    {
      path: 'user/:id',
      name: 'staffUserDetail',
      beforeEnter: (to, from, next) => {
        const userId = to.params.id
        const merchantId = store.getters.merchantActive.id

        if (
          !merchantStaffGetEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }

        store.dispatch('GET_USER', { merchantId, userId })
        next()
      },
      component: () =>
        import(
          /* webpackChunkName: "userDetail" */ '@/views/Team/Users/UserDetail.vue'
        )
    },
    {
      path: 'invite/',
      name: 'staffInvite',
      beforeEnter: (to, from, next) => {
        const query = isEmptyObject(to.query) ? { page: 1 } : to.query
        query.merchantId = store.getters.merchantActive.id

        if (
          !invitesSearchEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        store.dispatch('GET_TEAM_INVITES', query)
        next()
      },
      props: {
        actionName: 'GET_TEAM_INVITES',
        getterName: 'teamInvites',
        inviteRoute: 'staffInviteCreate'
      },
      component: () =>
        import(
          /* webpackChunkName: "inviteList" */ '@/views/Users/Invites/InviteList.vue'
        )
    },
    {
      path: 'invite/create',
      name: 'staffInviteCreate',
      beforeEnter: (to, from, next) => {
        if (
          !invitesCreateEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }
        next()
      },
      component: () =>
        import(
          /* webpackChunkName: "inviteCreate" */ '@/views/Users/Invites/InviteUserCreate.vue'
        )
    },
    {
      path: 'invite/:id',
      name: 'staffInviteDetail',
      beforeEnter: (to, from, next) => {
        const params = {
          query: {
            inviteId: to.params.id,
            merchantId: store.getters.merchantActive.id
          }
        }

        if (
          !invitesGetEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }

        store.dispatch('GET_INVITE', params)
        next()
      },
      component: () =>
        import(
          /* webpackChunkName: "inviteDetail" */ '@/views/Users/Invites/InviteDetail.vue'
        )
    }
  ]
}
export { routeStaff }
