import { UtilitiesInstance } from '@/store'

export function isEmptyObject (obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function formatData (date) {
  const parts = date.split('-')
  return `${parts[2]}/${parts[1]}`
}

export function sanitizeInput (e) {
  if (e.target.name === 'email')
    return UtilitiesInstance.commons
      .removeSpecialCharacters(e.target.value, 'email')
      .toLowerCase()
  else return UtilitiesInstance.commons.removeSpecialCharacters(e.target.value)
}
