<template>
  <div
    class="el-core__header"
    :class="{ _open: isOpen, '_drawer-open': drawerOpen }"
  >
    <app-core-header-left
      :isOpen="isOpen"
      @appMenuClicked="toggleAppMenu"
    ></app-core-header-left>
    <app-core-header-right
      :merchant="profile.merchant"
      :account="profile.merchant.account"
      :profile="profile.user"
      :isOpen="drawerOpen"
      @userLoggedOut="userLoggedOut"
      @drawerMenuClicked="toggleDrawerMenu"
    ></app-core-header-right>
  </div>
</template>

<script>
import AppCoreHeaderLeft from '@/components/project/ui/header/AppCoreHeaderLeft.vue'
import AppCoreHeaderRight from '@/components/project/ui/header/AppCoreHeaderRight.vue'

export default {
  name: 'AppCoreHeader',
  components: {
    AppCoreHeaderLeft,
    AppCoreHeaderRight
  },
  props: {
    isOpen: {
      type: Boolean
    },
    drawerOpen: {
      type: Boolean
    }
  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    }
  },
  methods: {
    userLoggedOut () {
      this.$emit('userLoggedOut')
    },
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    },
    toggleDrawerMenu () {
      this.$emit('drawerMenuClicked')
    },
    openOnboarding () {
      this.$emit('openOnboarding')
    }
  }
}
</script>
