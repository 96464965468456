import { CompanyTSInstance } from '@/store'

export const state = () => ({
  list: null
})

export const getters = {
  subscriptionList: state => {
    return state.list ? state.list : false
  }
}

export const mutations = {
  setSubscriptionList (state, subscriptions) {
    state.list = subscriptions
  }
}

// ACTIONS
// ===
export const actions = {
  GET_SUBSCRIPTIONS ({ commit, dispatch }, { merchantId, query }) {
    commit('setSubscriptionList', null)
    return CompanyTSInstance.subscription
      .getAll(merchantId)
      .then(response => {
        commit('setSubscriptionList', response)
        return response
      })
      .catch(err => ({ err, commit, dispatch }))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
