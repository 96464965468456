<template>
  <div
    class="emd-sidemenu"
    :class="{ _open: isOpen }"
  >
    <div class="emd-sidemenu__body">
      <app-menu
        simple
        :merchantId="merchantId"
      ></app-menu>
    </div>
  </div>
</template>

<script>
import AppMenu from '@/components/project/ui/menu/AppMenu.vue'

export default {
  name: 'AppSidemenu',
  components: {
    AppMenu
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    merchantId () {
      return this.$store.getters.merchantActive.id
    },
    isEmployee () {
      return this.$store.getters.isEmployee
    }
  },
  methods: {
    contextAnchorMerchant () {
      if (this.isEmployee) {
        window.location.assign(`${process.env.VUE_APP_BACKOFFICE_URL}`)
      } else {
        window.location.assign(`${process.env.VUE_APP_PROFILE_URL}/merchants`)
      }
    },
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
