import Cookies from 'js-cookie'
import { CompanyCore as CompanyTS } from 'company-ts'

const companyTS = new CompanyTS({
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
})
const envAcronym = companyTS.envAcronym

export const setCookieCompany = (data = {}) => {
  const cookieData = Cookies.get(`${envAcronym}mp_company`)

  if (cookieData) {
    Promise.resolve(atob(cookieData))
      .then(JSON.parse)
      .then(resCookieParse => {
        Cookies.set(
          `${envAcronym}mp_company`,
          btoa(JSON.stringify({ ...resCookieParse, ...data })),
          { expires: 0.5 }
        )
      })
  } else {
    Cookies.set(`${envAcronym}mp_company`, btoa(JSON.stringify({ ...data })), {
      expires: 0.5
    })
  }
}

export const updateCookieCompany = key => {
  const cookieData = Cookies.get(`${envAcronym}mp_company`)
  if (cookieData) {
    Promise.resolve(atob(cookieData))
      .then(JSON.parse)
      .then(resCookieParse => {
        delete resCookieParse[key]
        if (Object.entries(resCookieParse).length) {
          Cookies.set(
            `${envAcronym}mp_company`,
            btoa(JSON.stringify({ ...resCookieParse })),
            { expires: 0.5 }
          )
        } else {
          Cookies.remove(`${envAcronym}mp_company`)
        }
      })
  }
}
