import { CompanyTSInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'
import DateManager from '../../utils/date.manager'

export const state = () => ({
  isLoadingBestAccount: true,
  bestAccounts: []
})

export const getters = {
  BEST_ACCOUNTS_IS_LOADING (state) {
    return state.isLoadingBestAccount
  },
  BEST_ACCOUNTS (state) {
    return state.bestAccounts
  }
}

export const mutations = {
  SET_LOADING_BEST_ACCOUNTS (state, payload) {
    state.isLoadingBestAccount = payload
  },
  SET_BEST_ACCOUNTS (state, payload) {
    state.bestAccounts = payload
  }
}

export const actions = {
  GET_BEST_ACCOUNTS (context, params = {}) {
    context.commit('SET_LOADING_BEST_ACCOUNTS', true)

    const query = DateManager.handleDateParamsByQueryString(params?.query)

    return CompanyTSInstance.merchant
      .getBestAccounts(params.merchantId, query)
      .then(res => {
        context.commit(
          'SET_BEST_ACCOUNTS',
          res.insights?.buckets?.best_accounts ?? []
        )
        return Promise.resolve(res)
      })
      .catch(err => catchError(err, context))
      .finally(() => context.commit('SET_LOADING_BEST_ACCOUNTS', false))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
