import { DATE_PICKER_OPTIONS } from '../enums/date-picker.enum'

class DateManager {
  /**
   * Convets an iso date to DD/MM hh:mm format
   * @param {Date} currentDate
   * @param {String} locale
   * @returns {String} the formatted string
   *
   */
  static formatToDateTime (date, locale = 'pt-BR') {
    const dateFormatted = DateManager.formatToInputDate(date, locale)
    const hoursFormatted = DateManager.formatToInputHours(date, locale)
    return `${dateFormatted} ${hoursFormatted}`
  }

  /**
   * Converts an iso date to DD/MM format
   *
   * @param {Date} currentDate
   * @param {String} locale
   * @returns {String} the formatted string
   */
  static formatToInputDate (dateStringISO, locale = 'pt-BR') {
    const newUnformattedDate = new Date(dateStringISO)

    if (Number.isNaN(newUnformattedDate.getTime())) {
      throw new Error('Invalid date string')
    }

    const month = newUnformattedDate.getMonth() + 1
    const day = newUnformattedDate.getDate()

    const formattedMonth = month.toString().padStart(2, '0')
    const formattedDay = day.toString().padStart(2, '0')

    if (locale === 'en-US') {
      return `${formattedMonth}/${formattedDay}`
    }

    return `${formattedDay}/${formattedMonth}`
  }

  /**
   * Converts an iso date to HH:MM format
   * @param {Date} currentDate
   * @returns {String} the formatted string
   */
  static formatToInputHours (
    dateString,
    locale = 'pt-BR',
    timezone = 'America/Sao_Paulo'
  ) {
    const date = new Date(dateString)

    if (Number.isNaN(date.getTime())) {
      throw new Error('Invalid date string')
    }

    const options = {
      timeZone: timezone,
      hour: '2-digit',
      minute: '2-digit'
    }

    const formattedHours = date.toLocaleTimeString(locale, options)

    return formattedHours
  }

  /**
   *
   * @param {String | Date} startDate
   * @param {String | Date} endDate
   * @returns Boolean
   */
  static isSameDay (startDate, endDate) {
    if (!startDate || !endDate)
      throw new Error('1st and 2nd date are required!')

    if (isNaN(new Date(startDate).getTime())) {
      throw new Error('The 1st date is not valid')
    }

    if (isNaN(new Date(endDate).getTime())) {
      throw new Error('The 2nd date is not valid')
    }

    const date1st = startDate?.split('T')?.[0]
    const date2nd = endDate?.split('T')?.[0]

    return date1st === date2nd
  }

  /**
   *
   * @param {Date} currentDate new Date default
   * @returns new Date six months ago
   */
  static sixMonthsAgo (currentDate = new Date()) {
    if (isNaN(new Date(currentDate).getTime())) {
      throw new Error('Not a valid date')
    }
    const sixMonthsAgoDate = new Date(currentDate.getTime())
    sixMonthsAgoDate.setMonth(currentDate.getMonth() - 6)
    return sixMonthsAgoDate
  }

  /**
   *
   * @param {String | Date} startDate date
   * @param {String | Date} endDate date
   * @returns Number
   */
  static diffInDate (startDate, endDate) {
    if (!startDate || !endDate)
      throw new Error('It 1st and 2nd date are required!')

    if (isNaN(new Date(startDate).getTime())) {
      throw new Error('The 1st date is not valid')
    }

    if (isNaN(new Date(endDate).getTime())) {
      throw new Error('The 2nd date is not valid')
    }

    const diffInMs = new Date(endDate) - new Date(startDate)

    const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

    return Math.round(diffInDays)
  }

  /**
   *
   * @param {Object} dateParams object
   * @returns Object { date_filters: DATE_PICKER_OPTIONS | start_date: ISO && end_date: ISO }
   */
  static handleDateParamsByQueryString (queryParams = {}) {
    let query = JSON.parse(JSON.stringify(queryParams))

    if (!query || Object.keys(query)?.length === 0) {
      query = { date_filters: DATE_PICKER_OPTIONS.TODAY.id }
    }

    if (query?.created_since) {
      query = {
        start_date: query.created_since,
        end_date: query.created_until
      }

      delete query.created_since
      delete query.created_until
    }

    return query
  }
}

export default DateManager
