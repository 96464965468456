import store from '@/store'
import insightsMerchantEnabled from '../actions/merchant-type.actions'

const routeHome = {
  path: 'insights',
  name: 'insights',
  component: () =>
    import(/* webpackChunkName: "home" */ '@/views/Home/index.vue'),
  beforeEnter: (to, from, next) => {
    const { merchant_id: merchantId } = to.params

    const shouldViewInsights = insightsMerchantEnabled(
      store.getters.permissionIsEnabled,
      store.getters.userType
    )

    if (!shouldViewInsights) {
      return next({ name: 'accountProduction' })
    }

    const params = {
      merchantId,
      query: to?.query ?? {}
    }

    store.dispatch('GET_DATA_INSIGHTS', params).then(() => {
      store.dispatch('GET_BEST_ACCOUNTS', params)
      next()
    })
  }
}

export { routeHome }
