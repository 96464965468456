<template>
  <div class="el-section el-centered eu-align-items_center">
    <emd-pagarme-loader color="secondary" />
  </div>
</template>
<script>
import { EmdPagarmeLoader } from 'emerald-vue/src/components'
export default {
  name: 'GlobalLoader',
  components: {
    EmdPagarmeLoader
  },
  computed: {
    pref () {
      return this.$store.getters.pref
    }
  },
  beforeMount: function () {
    const body = document.body
    this.$store.dispatch('GET_PREFERENCES').then(() => {
      const preferences = Object.assign({}, this.pref)
      if (preferences.appearance.mode === 'light') {
        body.classList.remove('_mode_dark')
      } else {
        body.classList.add('_mode_dark')
      }
      this.$store.dispatch('SET_PREFERENCES', preferences)
    })
  }
}
</script>
