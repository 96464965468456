const MERCHANT_TYPES = {
  staff: 'Staff',
  admin: 'Admin',
  owner: 'Owner',
  manager: 'Manager',
  analyst: 'Analyst'
}

const isStaffType = type => {
  return MERCHANT_TYPES.staff.toUpperCase() === type.toUpperCase()
}

const isManagerType = type => {
  return MERCHANT_TYPES.manager.toUpperCase() === type.toUpperCase()
}

const isAnalystType = type => {
  return MERCHANT_TYPES.analyst.toUpperCase() === type.toUpperCase()
}

const isAdmin = type => {
  return MERCHANT_TYPES.admin.toUpperCase() === type.toUpperCase()
}
const isOwner = type => {
  return MERCHANT_TYPES.owner.toUpperCase() === type.toUpperCase()
}

export {
  MERCHANT_TYPES,
  isStaffType,
  isManagerType,
  isAnalystType,
  isAdmin,
  isOwner
}
