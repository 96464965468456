const components = {
  logo: {
    pagarme: ['pagarme'],
    jpmorgan: ['jpmorgan']
  },
  filter: {
    tagInfo: ['pagarme'],
    tagPrimary: ['jpmorgan']
  },
  sidemenu: {
    finance: ['pagarme', 'jpmorgan'],
    logoFooter: ['pagarme']
  },
  roles: {
    shop: ['pagarme'],
    readOnly: ['jpmorgan'],
    psp: ['pagarme']
  },
  finance: {
    currencyPagarme: ['pagarme', 'jpmorgan']
  },
  global: {
    onboarding: ['pagarme'],
    nps: ['pagarme'],
    pix: ['pagarme'],
    modalHeader: ['pagarme']
  },
  globalLoader: {
    pagarme: ['pagarme', 'jpmorgan']
  },
  globalError: {
    logo: ['pagarme']
  },
  subscribe: {
    titlePagarme: ['pagarme', 'jpmorgan']
  },
  footer: {
    textJPMorgan: ['jpmorgan'],
    textPagarme: ['pagarme'],
    switchThemeMode: [],
    status: ['pagarme'],
    help: ['pagarme'],
    documentation: ['pagarme', 'jpmorgan'],
    privacySecurity: ['jpmorgan'],
    urlSite: ['pagarme'],
    switchLanguage: ['pagarme', 'jpmorgan'],
    privacyPolicy: ['pagarme'],
    termsOfUse: ['pagarme'],
    termsOfUseModal: [],
    secureEnv: ['pagarme', 'jpmorgan']
  },
  backofficeIcon: {
    pagarmeIcon: ['pagarme', 'jpmorgan']
  }
}
export default components
