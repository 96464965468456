import { CompanyTSInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'
import DateManager from '../../utils/date.manager'

export const state = () => ({
  isLoading: true,
  data: null
})

export const getters = {
  INSIGHTS_DATA_IS_LOADING (state) {
    return state.isLoading
  },
  INSIGHTS_DATA (state) {
    return state.data
  }
}

export const mutations = {
  SET_LOADING (state, payload) {
    state.isLoading = payload
  },
  SET_INSIGHTS_DATA (state, payload) {
    state.data = payload
  }
}

export const actions = {
  GET_DATA_INSIGHTS (context, params = {}) {
    context.commit('SET_LOADING', true)

    const query = DateManager.handleDateParamsByQueryString(params?.query)

    return CompanyTSInstance.merchant
      .getInsights(params.merchantId, query)
      .then(res => {
        context.commit('SET_INSIGHTS_DATA', res.insights)
        return Promise.resolve(res)
      })
      .catch(err => catchError(err, context))
      .finally(() => context.commit('SET_LOADING', false))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
