import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import App from './App.vue'
import router from './router'
import store from './store/index'
import i18n from './i18n'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import getGoogleAnalyticsIds from './utils/googleAnalytics'
import { init as initFS } from '@fullstory/browser'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { dateFormat } from './filters'

// FONT AWESOME CONFIG & IMPORTS
import './plugins/click-outside'

// datepicker
import 'vanillajs-datepicker/dist/css/datepicker.css'

import 'emerald-workbench/dist/emerald.pagarme.min.css'
if (['jpmorgan', 'pagarme'].includes(process.env.VUE_APP_THEME)) {
  store.dispatch('LOAD_EMERALD_BY_THEME', process.env.VUE_APP_THEME)
}

Vue.config.productionTip = false

Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.filter('dateFormat', dateFormat)
Vue.prototype.$sanitize = sanitizeUrl

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  initFS({ orgId: 'BKP05' })

  const ids = getGoogleAnalyticsIds()
  ids.length &&
    Vue.use(VueAnalytics, {
      id: ids,
      router,
      debug: {
        enabled: false,
        sendHitTask: process.env.VUE_APP_CORE_ENV
      }
    })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
