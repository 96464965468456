export const MERCHANT_ALLOW_LIST = {
  view: 'merchant.allowlist:view',
  edit: 'merchant.allowlist:edit'
}

export const merchantAllowListViewEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANT_ALLOW_LIST.view)

export const merchantAllowListEditEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANT_ALLOW_LIST.edit)
