import actionsPermissionIsEnabled from '.'
import { isStaffType, MERCHANT_TYPES } from '@/enums/merchant-type.enum'

export const MERCHANTADMIN_ACTIONS_ENUM = {
  transfer_ownership: 'merchant_admin:transfer_ownership',
  search: 'merchant_admin:search',
  edit: 'merchant_admin:edit',
  get: 'merchant_admin:get',
  invite_get: 'merchant_admin.invite:get',
  invite_create: 'merchant_admin.invite:create'
}

const MERCHANTADMIN_ACTIONS = Object.values(MERCHANTADMIN_ACTIONS_ENUM)

const merchantAdminEnabled = permissionIsEnabledFn =>
  actionsPermissionIsEnabled(MERCHANTADMIN_ACTIONS, permissionIsEnabledFn)

const merchantAdminTransferOwnershipEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTADMIN_ACTIONS_ENUM.transfer_ownership)

const merchantAdminGetEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTADMIN_ACTIONS_ENUM.get)

const merchantAdminGetEnabledAndIsNotStaff = (
  permissionIsEnabledFn,
  { key: merchantType } = MERCHANT_TYPES.staff
) =>
  merchantAdminGetEnabled(permissionIsEnabledFn) && !isStaffType(merchantType)

const merchantAdminSearchEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTADMIN_ACTIONS_ENUM.search)

const merchantAdminSearchEnabledAndIsNotStaff = (
  permissionIsEnabledFn,
  { key: merchantType } = MERCHANT_TYPES.staff
) =>
  merchantAdminSearchEnabled(permissionIsEnabledFn) &&
  !isStaffType(merchantType)

const merchantAdminEditEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTADMIN_ACTIONS_ENUM.edit)

const merchantAdminInvitesGetEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTADMIN_ACTIONS_ENUM.invite_get)

const merchantAdminInvitesGetEnabledAndIsNotStaff = (
  permissionIsEnabledFn,
  { key: merchantType } = MERCHANT_TYPES.staff
) =>
  merchantAdminInvitesGetEnabled(permissionIsEnabledFn) &&
  !isStaffType(merchantType)

const merchantAdminInvitesCreateEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTADMIN_ACTIONS_ENUM.invite_create)

const merchantAdminInvitesCreateEnabledAndIsNotStaff = (
  permissionIsEnabledFn,
  { key: merchantType } = MERCHANT_TYPES.staff
) =>
  merchantAdminInvitesCreateEnabled(permissionIsEnabledFn) &&
  !isStaffType(merchantType)

export {
  merchantAdminGetEnabled,
  merchantAdminTransferOwnershipEnabled,
  merchantAdminSearchEnabled,
  merchantAdminEditEnabled,
  merchantAdminInvitesGetEnabled,
  merchantAdminInvitesCreateEnabled,
  merchantAdminSearchEnabledAndIsNotStaff,
  merchantAdminGetEnabledAndIsNotStaff,
  merchantAdminInvitesGetEnabledAndIsNotStaff,
  merchantAdminInvitesCreateEnabledAndIsNotStaff
}

export default merchantAdminEnabled
