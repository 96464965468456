import store, { ProfileTSInstance } from '@/store'

const activeMode = ProfileTSInstance.me.getPreferences().appearance.mode

const toggleThemeMode = modeToggle => {
  const body = document.querySelector('body')
  if (modeToggle) {
    store.dispatch('updateTheme', 'dark')
    body.classList.contains('_mode_dark') || body.classList.add('_mode_dark')
  } else {
    store.dispatch('updateTheme', 'light')
    body.classList.remove('_mode_dark')
  }
}

export const validateActiveMode = () => {
  store.dispatch('updateTheme', activeMode)
  if (activeMode === 'dark') return true
  else if (activeMode === 'light') return false
  return false
}

export const applyThemeOnBody = () => {
  const body = document.querySelector('body')

  if (activeMode === 'dark') body.classList.add('_mode_dark')
  else body.classList.remove('_mode_dark')
}

export default toggleThemeMode
