import { MERCHANT_TYPES, isStaffType } from '../enums/merchant-type.enum'
import actionsPermissionIsEnabled from '.'

export const INSIGHTS_ACTIONS_ENUM = {
  view: 'insights.merchant:view'
}

const INSIGHTS_ACTIONS = [INSIGHTS_ACTIONS_ENUM.view]

const insightsMerchantEnabled = (
  permissionIsEnabledFn,
  { key: merchantType } = MERCHANT_TYPES.staff
) => {
  return (
    !isStaffType(merchantType) &&
    actionsPermissionIsEnabled(INSIGHTS_ACTIONS, permissionIsEnabledFn)
  )
}

export default insightsMerchantEnabled
