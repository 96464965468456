<template>
  <nav
    class="emd-menu"
    :class="{ _simple: simple }"
  >
    <main class="emd-menu__main">
      <template v-for="(section, key) in menu">
        <router-link
          v-if="section.showSection"
          class="emd-menu__section"
          active-class="_active"
          :to="`/${merchantId}/${key}`"
          :id="key"
          :key="key"
        >
          <section class="emd-menu__section">
            <a href="javascript:void(0)">
              <span class="emd-menu__icon">
                <i :class="['uil', `uil-${section.icon}`]"></i>
              </span>
              <span class="emd-menu__label titleCase">{{
                $t(`ui.appMenu.${key}`)
              }}</span>
              <span
                v-if="section.demo"
                class="emd-tag _color_info eu-ml_2 eu-display_none eu-display_inline_md"
                >Demo</span
              >
            </a>
          </section>
        </router-link>
      </template>
      <section
        class="emd-menu__section"
        v-if="statisticsAvailability && permissionIsEnabled('finance:view')"
      >
        <a :href="$sanitize(urlFinance)">
          <span class="emd-menu__icon">
            <i :class="['uil', 'uil-chart-bar']"></i>
          </span>
          <span class="emd-menu__label titleCase">{{
            $t('ui.appMenu.finance')
          }}</span>
        </a>
      </section>
      <section class="emd-menu__section">
        <a
          :href="$sanitize(urlHelpCenter)"
          target="_blank"
        >
          <span class="emd-menu__icon">
            <i :class="['uil', 'uil-question-circle']"></i>
          </span>
          <span class="emd-menu__label titleCase">{{
            $t('ui.appMenu.helpCenter')
          }}</span>
        </a>
      </section>
    </main>
  </nav>
</template>

<script>
import insightsMerchantEnabled from '@/actions/merchant-type.actions.js'
import { mapGetters } from 'vuex'
import chargeEnabled from '@/actions/charges.actions'
import { merchantAdminSearchEnabledAndIsNotStaff } from '@/actions/merchant-admin.actions'
import { merchantStaffSearchEnabledAndIsNotStaff } from '@/actions/merchant-staff.actions'
import { MERCHANT_ALLOW_LIST } from '@/actions/merchant-allowlist.actions'
import {
  SETTINGS_FUNCTIONALITIES_ACTIONS,
  SETTINGS_GENERAL_DATA_ACTIONS,
  SETTINGS_INFO_ACTIONS
} from '@/actions/settings.actions'

export default {
  name: 'AppMenu',
  props: {
    simple: {
      type: Boolean,
      default: false,
      required: false
    },
    merchantId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['permissionIsEnabled', 'merchant']),
    menu () {
      return {
        insights: {
          icon: 'chart-growth',
          showSection: insightsMerchantEnabled(
            this.permissionIsEnabled,
            this.merchant.type
          )
        },
        accounts: {
          icon: 'store',
          showSection: true
        },
        charges: {
          icon: 'credit-card',
          showSection: chargeEnabled(this.permissionIsEnabled)
        },
        myplan: {
          icon: 'files-landscapes-alt',
          showSection: this.permissionIsEnabled('billing.invoice:search')
        },
        admin: {
          icon: 'lock-alt',
          showSection: merchantAdminSearchEnabledAndIsNotStaff(
            this.permissionIsEnabled,
            this.merchant.type
          )
        },
        staff: {
          icon: 'channel',
          showSection: merchantStaffSearchEnabledAndIsNotStaff(
            this.permissionIsEnabled,
            this.merchant.type
          )
        },
        configuration: {
          icon: 'cog',
          showSection: [
            SETTINGS_FUNCTIONALITIES_ACTIONS.view,
            SETTINGS_GENERAL_DATA_ACTIONS.view,
            SETTINGS_INFO_ACTIONS.get,
            MERCHANT_ALLOW_LIST.view
          ].some(this.permissionIsEnabled)
        }
      }
    },
    routeName () {
      return this.$route.name
    },
    statisticsAvailability () {
      return this.$store.getters.statisticsAvailability
    },
    merchantDelinquent () {
      return this.$store.getters.merchantDelinquent
    },
    urlFinance () {
      return `${process.env.VUE_APP_FINANCE_URL}/${this.merchantId}/summary`
    },
    urlHelpCenter () {
      return process.env.VUE_APP_URL_HELP
    }
  }
}
</script>

<style scoped>
.emd-menu__section._disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.emd-menu__section._disabled a,
.emd-menu__section._disabled a .emd-menu__label {
  pointer-events: none;
}

@media (max-width: 767px) {
  .ccg-section {
    position: relative;
  }

  .ccg-tag {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

@media (min-width: 768px) {
  .titleCase {
    text-transform: none;
  }
}
</style>
