import { DashInstance, ProfileTSInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

export const state = () => ({
  list: null
})

export const getters = {
  accountList: state => {
    return state.list ? state.list : false
  }
}

export const mutations = {
  setAccountList (state, accounts) {
    state.list = accounts
  }
}

export const actions = {
  GET_ACCOUNTS ({ commit, dispatch }, { merchantId, query }) {
    commit('setAccountList', null)
    if (!query.size) query.size = 30
    if (!query.page) query.page = 1

    return ProfileTSInstance.account
      .getAll(merchantId, query)
      .then(response => {
        commit('setAccountList', response.data)
      })
      .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
  },
  CREATE_ACCOUNT ({ commit, dispatch }, { merchantId, body }) {
    return DashInstance.account
      .create(merchantId, body)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  CREATE_TEST_ACCOUNT ({ commit, dispatch }, { merchantId, body }) {
    return DashInstance.account
      .createTestAccount(merchantId, body)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
