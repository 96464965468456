<template>
  <div
    class="emd-container _footer_spaced _footer_end_md _content_spaced eu-border-top eu-border-width_strong eu-border-color_primary"
  >
    <div class="emd-container__header eu-justify_end eu-align-items_start">
      <a
        v-if="npsComplete"
        href="javascript:void(0)"
        class="emd-alert__close"
        @click="close"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 30 30"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="close-with-timer"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <circle
              id="timer"
              stroke-width="3"
              cx="15"
              cy="15"
              r="13"
            />
            <path
              d="M17.2745455,15 L20.6860227,11.5885227 C21.1046591,11.1698864 21.1046591,10.4911364 20.6860227,10.0721591 L19.9278409,9.31397727 C19.5092045,8.89534091 18.8304545,8.89534091 18.4114773,9.31397727 L15,12.7254545 L11.5885227,9.31397727 C11.1698864,8.89534091 10.4911364,8.89534091 10.0721591,9.31397727 L9.31397727,10.0721591 C8.89534091,10.4907955 8.89534091,11.1695455 9.31397727,11.5885227 L12.7254545,15 L9.31397727,18.4114773 C8.89534091,18.8301136 8.89534091,19.5088636 9.31397727,19.9278409 L10.0721591,20.6860227 C10.4907955,21.1046591 11.1698864,21.1046591 11.5885227,20.6860227 L15,17.2745455 L18.4114773,20.6860227 C18.8301136,21.1046591 19.5092045,21.1046591 19.9278409,20.6860227 L20.6860227,19.9278409 C21.1046591,19.5092045 21.1046591,18.8304545 20.6860227,18.4114773 L17.2745455,15 Z"
              id="xis"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </a>
    </div>

    <div
      class="emd-container__content"
      v-if="!npsComplete"
    >
      <div
        class="emd-text _align_center _size_section _type_hint _color_contrast"
      >
        <p class="eu-mb_5">{{ $t('title') }}</p>
      </div>
      <div
        class="emd-nps eu-container"
        v-bind:class="{ '_has-selected': hasSelected }"
      >
        <ul
          class="emd-nps__indicator eu-flex eu-justify_space-between eu-mb_2 eu-no-margin"
        >
          <li
            v-for="(item, index) in items"
            :key="item.number"
          >
            <button
              class="emd-btn"
              :value="item.number"
              @click="selected(index)"
              v-bind:class="{ _selected: items[index].isSelected }"
            >
              {{ item.number }}
            </button>
          </li>
        </ul>
        <div class="eu-flex eu-justify_space-between eu-mb_5 eu-no-margin">
          <p class="emd-text _color_subtle emd-nps__subtitle">
            {{ $t('notLikely') }}
          </p>
          <p class="emd-text _color_subtle emd-nps__subtitle">
            {{ $t('forSure') }}
          </p>
        </div>

        <div
          class="emd-nps__comment emd-form-group eu-pt_3 eu-fade-in"
          v-if="npsComment"
        >
          <label class="emd-label emd-text _color_contrast">{{
            $t('question')
          }}</label>
          <textarea
            class="emd-input emd-text _color_contrast"
            v-model="comment"
            placeholder="Adicione um comentário"
            value
          ></textarea>
          <p class="emd-input-feedback emd-text _color_contrast">
            {{ $t('comment') }}
          </p>
          <button
            class="emd-nps__btn emd-btn _color_tertiary eu-float_right"
            @click="complete"
          >
            {{ $t('send') }}
          </button>
        </div>
      </div>
    </div>

    <div
      class="emd-container__content eu-fade-in"
      v-if="npsComplete"
    >
      <h2 class="emd-text _size_page _color_contrast _align_center eu-mb_3">
        {{ $t('titleComplete') }}
      </h2>
      <div
        class="emd-text _align_center _size_section _type_hint _color_contrast"
      >
        <p class="eu-mb_5">{{ $t('textComplete') }}</p>
      </div>
    </div>

    <div
      class="emd-container__footer eu-align_center eu-mb_3 eu-mr_2 _footer_spaced_mb"
    >
      <a
        href="javascript:void(0)"
        class="emd-btn _type_text _color_tertiary eu-float_right"
        @click="ignore"
        v-if="!hasSelected"
        >{{ $t('btnIgnore') }}</a
      >

      <button
        v-if="!hasSelected"
        class="emd-btn _type_outline _color_tertiary eu-float_right"
        @click="postpone"
      >
        {{ $t('btnLater') }}
      </button>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "title": "De 0 a 10, qual a probabilidade de você recomendar a Mundipagg para um amigo ou colega?",
    "notLikely": "Nem um pouco provável",
    "forSure": "Extremamente provável",
    "question": "Quais os principais motivos para a sua avaliação?",
    "comment": "Comentário opcional",
    "btnIgnore": "Ignorar",
    "btnLater": "Responder depois",
    "titleComplete": "Obrigada!",
    "textComplete": "Sua avaliação foi enviada e será essencial para a melhora dos nossos serviços.",
    "send": "Enviar"
  },
  "en-US": {
    "title": "From 0 to 10, how likely are you to recommend Mundipagg to a friend or colleague?",
    "notLikely": "Not at all likely",
    "forSure": "Extremely likely",
    "question": "What are the main reasons for your assessment?",
    "comment": "Optional comment",
    "btnIgnore": "Ignore",
    "btnLater": "Reply later",
    "titleComplete": "Thanks!",
    "textComplete": "Your evaluation has been sent and will be essential for the improvement of our services.",
    "send": "Send"
  }
}
</i18n>

<script>
export default {
  name: 'Nps',
  data () {
    return {
      comment: null,
      rating: null,
      hasSelected: false,
      npsComment: false,
      npsComplete: false,
      items: [
        {
          number: '0',
          isSelected: false
        },
        {
          number: '1',
          isSelected: false
        },
        {
          number: '2',
          isSelected: false
        },
        {
          number: '3',
          isSelected: false
        },
        {
          number: '4',
          isSelected: false
        },
        {
          number: '5',
          isSelected: false
        },
        {
          number: '6',
          isSelected: false
        },
        {
          number: '7',
          isSelected: false
        },
        {
          number: '8',
          isSelected: false
        },
        {
          number: '9',
          isSelected: false
        },
        {
          number: '10',
          isSelected: false
        }
      ]
    }
  },
  methods: {
    selected (index) {
      this.items.forEach((item, i) => {
        if (index === i) {
          item.isSelected = !item.isSelected
        } else {
          item.isSelected = false
        }
      })
      this.hasSelected = this.items[index].isSelected
      this.npsComment = this.items[index].isSelected

      this.rating = index
    },
    complete () {
      const body = {
        comment: this.comment,
        ignore: false,
        postpone: false,
        rating: this.rating
      }
      this.sendAnswer(body)
    },
    ignore () {
      const body = {
        comment: null,
        ignore: true,
        postpone: false,
        rating: null
      }
      this.sendAnswer(body)
    },
    postpone () {
      const body = {
        comment: null,
        ignore: false,
        postpone: true,
        rating: null
      }
      this.sendAnswer(body)
    },
    sendAnswer (body) {
      this.$store.dispatch('SET_ANSWER_NPS', body).then(() => {
        if (!body.ignore && !body.postpone) {
          this.npsComplete = true
          this.autoClose()
        } else {
          this.close()
        }
      })
    },
    autoClose () {
      setTimeout(() => {
        this.$emit('close')
      }, 7000)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
