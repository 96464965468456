import i18n from '@/i18n'

export function catchError (error, { commit, dispatch }) {
  if (error.status === 401) {
    dispatch('DELETE_AUTHORIZATION')
    window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
  } else if (error.status === 412) {
    const messages = [{ message: i18n.t('error.412.description') }]
    error.body = { errors: messages }
  } else if (error.status === 400 && error.body.notifications) {
    const messages = Object.values(error.body.notifications).map(value => ({
      message: value[0]
    }))
    error.body.errors
      ? error.body.errors.push(messages)
      : (error.body.errors = messages)
  } else if (error.status !== 400 && error.status !== 402) {
    commit('toggleGlobalLoading', false)
    commit('toggleGlobalError', { status: true, code: error.status })
  }
  if (window.newrelic) {
    window.newrelic.noticeError(error.message)
  }
  return Promise.reject(error)
}
