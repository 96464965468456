<template>
  <div class="el-core__header__left">
    <div class="emd-sidemenu__trigger">
      <emd-button-menu
        :btnMenuState="isOpen"
        @btnMenuClicked="toggleAppMenu"
      />
    </div>
    <div
      data-onboarding="navigation"
      class="emd-control"
      :class="statusClassList"
    >
      <div class="emd-control__wrapper">
        <span class="emd-control__status">
          {{ $t(status.label) }}
        </span>
        <div class="emd-control__link">
          <div class="emd-control__icon">
            <i
              class="uil uil-building"
              transform="width-20"
            ></i>
          </div>
          <a
            v-if="merchantName"
            href="javascript:void(0);"
            class="emd-control__text"
            :title="merchantName"
          >
            <span
              id="merchantName"
              class="emd-control__text__item"
            >
              {{ merchantName }}
            </span>
          </a>
        </div>
        <emd-tippy
          class="_no-wrap"
          id="merchant"
          type="ghost"
          position="left"
          :content="$t('tooltip.merchant')"
        >
          <button
            class="emd-action-button _has-tooltip _type_ghost"
            :data-tippy-content="$t('tooltip.merchant')"
            aria-expanded="false"
            @click="contextAnchorMerchant"
          >
            <i
              fixed-width
              class="emd-action-button__icon uil uil-exchange"
              transform="width-16"
            ></i>
          </button>
        </emd-tippy>
      </div>
    </div>
    <div class="eu-ml_4 eu-display_none eu-display_block_md">
      <div class="emd-logo-custom">
        <div class="emd-logo-custom__image"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import EmdButtonMenu from '@/components/emerald/atoms/EmdButtonMenu.vue'
import EmdTippy from '@/components/emerald/atoms/EmdTippy'

import { STATUS_ACCOUNT } from './status-merchant.enum'

export default {
  name: 'AppCoreHeaderLeft',
  components: {
    EmdButtonMenu,
    EmdTippy
  },
  props: {
    isOpen: Boolean
  },

  computed: {
    ...mapGetters(['merchantDelinquent', 'merchantSummary', 'isEmployee']),
    merchantName () {
      return this.merchantSummary?.name
    },
    status () {
      // TODO: commentado pois vamos passar a pegar o status da merchant do summary e estamos esperando o BFF mudar este endpoint.
      // Depois é só remover o return realizado e descomentar este código abaixo:

      // if (this.merchantDelinquent) return STATUS_ACCOUNT.inactive

      // return (
      //   Object.values(STATUS_ACCOUNT).find(el =>
      //     el.status.includes(this.merchantSummary.status?.key)
      //   ) ?? STATUS_ACCOUNT.inactive
      // )

      return this.merchantDelinquent
        ? STATUS_ACCOUNT.inactive
        : STATUS_ACCOUNT.active
    },
    statusClassList () {
      return [this.status.style]
    }
  },
  methods: {
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    },
    contextAnchorMerchant () {
      if (this.isEmployee) {
        window.location.assign(`${process.env.VUE_APP_BACKOFFICE_URL}`)
      } else {
        window.location.assign(`${process.env.VUE_APP_PROFILE_URL}/merchants`)
      }
    }
  }
}
</script>

<i18n>
  {
    "pt-BR": {
      "tooltip": {
        "merchant": "Trocar Empresa"
      },
      "status": {
        "inactive": "Inativo",
        "blocked": "Bloqueado",
        "active": "Ativo",
        "pending": "Pendente",
        "testing": "Teste"
      }
    },
    "en-US": {
      "tooltip": {
        "merchant": "Change Merchant"
      },
      "status": {
        "inactive": "Inactive",
        "blocked": "Blocked",
        "active": "Active",
        "pending": "Pending",
        "testing": "Test"
      }
    }
  }
  </i18n>
