import actionsPermissionIsEnabled from '.'

export const CHARGE_ACTIONS_ENUM = {
  view: 'charge:view',
  search: 'charge:search',
  get: 'charge:get'
}

const CHARGE_ACTIONS = Object.values(CHARGE_ACTIONS_ENUM)

const chargeEnabled = permissionIsEnabledFn => {
  return actionsPermissionIsEnabled(CHARGE_ACTIONS, permissionIsEnabledFn)
}

const chargeViewEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGE_ACTIONS_ENUM.view)
}

const chargeSearchEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGE_ACTIONS_ENUM.search)
}

const chargeGetEnabled = permissionIsEnabledFn => {
  return permissionIsEnabledFn(CHARGE_ACTIONS_ENUM.get)
}

export { chargeViewEnabled, chargeSearchEnabled, chargeGetEnabled }

export default chargeEnabled
