import { ScopeInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

export const state = () => ({
  adminInvites: null,
  teamInvites: null,
  invite: null
})

export const getters = {
  adminInvites (state) {
    return state.adminInvites ? state.adminInvites : false
  },
  teamInvites (state) {
    return state.teamInvites ? state.teamInvites : false
  },
  inviteDetail (state) {
    return state.invite ? state.invite : false
  }
}

export const mutations = {
  setAdminInvites (state, invites) {
    state.adminInvites = invites
  },
  setTeamInvites (state, invites) {
    state.teamInvites = invites
  },
  setInvite (state, invite) {
    state.invite = invite
  }
}
export const actions = {
  GET_ADMIN_INVITES ({ commit, dispatch }, params) {
    commit('setAdminInvites', null)
    params = params.query
      ? Object.assign(params.query, { merchantId: params.merchantId })
      : params
    ScopeInstance.invite
      .getAllAdmin(params)
      .then(response => {
        commit('setAdminInvites', response.data)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_TEAM_INVITES ({ commit, dispatch }, params) {
    commit('setTeamInvites', null)
    params = params.query
      ? Object.assign(params.query, { merchantId: params.merchantId })
      : params
    ScopeInstance.invite
      .getAllStaff(params)
      .then(response => {
        commit('setTeamInvites', response.data)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_INVITE ({ commit, dispatch }, params) {
    commit('setInvite', null)
    ScopeInstance.invite
      .getById(params.query)
      .then(response => {
        commit('setInvite', response.data)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_INVITE_ADMIN ({ commit, dispatch }, params) {
    commit('setInvite', null)
    ScopeInstance.invite
      .getByIdAsAdmin(params.query)
      .then(response => {
        commit('setInvite', response.data)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  CREATE_INVITE ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .create(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  CREATE_INVITE_ADMIN ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .createAsAdmin(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  CREATE_INVITE_ACCOUNT ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .createAsAccount(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  CREATE_INVITE_MERCHANT ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .createAsMerchant(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  RESEND_INVITE ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .resend(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  RESEND_INVITE_ADMIN ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .resendAsAdmin(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  RESEND_INVITE_ACCOUNT ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .resendAsAccount(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  CANCEL_INVITE ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .cancel(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  CANCEL_INVITE_ADMIN ({ commit, dispatch }, params = {}) {
    return ScopeInstance.invite
      .cancelAsAdmin(params.query, params.body)
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
