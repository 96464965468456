<template>
  <div class="emd-onboarding__greeting eu-mb_4">
    <div class="emd-container _footer_centered _footer_end_md">
      <div
        class="emd-container__header eu-height_auto eu-justify_end eu-align-items_start"
      >
        <a
          href="javascript:void(0)"
          class="emd-btn _color_contrast _type_link"
          @click="close"
        >
          <i class="uil uil-times"></i>
        </a>
      </div>
      <div class="emd-container__content">
        <div class="emd-onboarding__image"></div>
        <h2 class="emd-text _size_page">
          {{ $t('hello') }}
        </h2>
        <i18n
          path="message"
          tag="div"
          class="emd-text eu-my_2"
        >
          <router-link
            :to="{ name: 'accountProduction' }"
            class="emd-link _color_tertiary"
            >{{ $t('accountTab') }}</router-link
          >
        </i18n>
      </div>
      <div class="emd-container__footer">
        <button
          class="emd-btn _color_tertiary"
          @click="close"
        >
          {{ $t('button') }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "hello": "Bem vindo a sua página de empresa!",
    "message": "Aqui administradores podem gerenciar usuários, convites e configurações gerais. Na {0} você pode visualizar a lista de lojas.",
    "accountTab": "aba de contas",
    "button": "ok, entendi"
  },
  "en-US": {
    "hello": "Welcome to your company!",
    "message": "Admins can invite user, manage then and check your plan payments status. In the {0} you can view the list of accounts you have acess in this company.",
    "accountTab": "accounts tab",
    "button": "ok, got it"
  }
}
</i18n>

<script>
export default {
  name: 'Greeting',
  computed: {
    themeMode () {
      return this.$store.getters.pref.appearance.mode === 'dark'
        ? 'dark'
        : 'light'
    },
    appTheme () {
      return this.$store.getters.whitelabel.theme
    },
    folder () {
      return this.componentIsEnabled('footer', 'switchThemeMode')
        ? this.themeMode
        : this.appTheme
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
