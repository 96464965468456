<template>
  <div
    class="emd-dropdown"
    v-click-outside="closeDropdown"
  >
    <div
      class="emd-dropdown__trigger"
      :class="{ _active: isActive }"
      @click="toggleDropdown"
    >
      <slot name="trigger"></slot>
    </div>
    <div
      class="emd-dropdown__content"
      :class="{ _active: isActive }"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmdDropDown',
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    toggleDropdown () {
      this.isActive = !this.isActive
    },
    closeDropdown () {
      this.isActive = false
    }
  }
}
</script>
