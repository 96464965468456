import store from '@/store'
import { isEmptyObject } from '@/support/helpers.js'
import { catchError } from '@/support/errorLogger.js'

const routeMyPlan = {
  path: 'myplan/',
  beforeEnter: (to, from, next) => {
    const merchantId = store.getters.merchantActive.id
    if (store.getters.permissionIsEnabled('billing.invoice:search')) {
      next()
    } else {
      next({ name: 'myPlanSubscribe', params: { merchant_id: merchantId } })
    }
  },
  component: () =>
    import(
      /* webpackChunkName: "subscription" */ '@/views/MyPlan/Subscription.vue'
    ),
  children: [
    {
      path: '',
      redirect: { name: 'invoices' }
    },
    {
      path: 'list/',
      name: 'myplan',
      beforeEnter: (to, from, next) => {
        next({ name: 'invoices' })
      },
      component: () =>
        import(
          /* webpackChunkName: "subscriptions" */ '@/views/MyPlan/List.vue'
        )
    },
    {
      path: 'subscribe/',
      name: 'subscribe',
      beforeEnter: (to, from, next) => {
        next()
      },
      component: () =>
        import(
          /* webpackChunkName: "subscribe" */ '@/views/MyPlan/Subscribe.vue'
        )
    },
    {
      path: 'invoices/',
      name: 'invoices',
      beforeEnter: (to, from, next) => {
        if (store.getters.permissionIsEnabled('billing.invoice:search')) {
          const merchantId = store.getters.merchantActive.id
          const query = isEmptyObject(to.query) ? { page: 1 } : to.query
          store
            .dispatch('GET_INVOICES', { merchantId, query })
            .then(response => {
              if (response.err) {
                const commit = response.commit
                const dispatch = response.dispatch

                if (response.err.status !== 404) {
                  catchError(response.err, { commit, dispatch })
                }
              }
            })
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "invoices" */ '@/views/MyPlan/Invoice/History.vue'
        )
    },
    {
      path: 'invoices/:invoice_id/detail/',
      name: 'invoices-detail',
      beforeEnter: (to, from, next) => {
        if (store.getters.permissionIsEnabled('billing.invoice:get')) {
          const merchantId = store.getters.merchantActive.id
          const invoiceId = to.params.invoice_id
          store.dispatch('GET_INVOICE', { merchantId, invoiceId })
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "invoiceDetail" */ '@/views/MyPlan/Invoice/Detail.vue'
        )
    },
    {
      path: ':subscription_id/invoices/',
      name: 'subscription-invoices',
      beforeEnter: (to, from, next) => {
        const customerId = store.getters.merchantCustomerId
        const subscriptionId = to.params.subscription_id
        const query = isEmptyObject(to.query) ? { page: 1 } : to.query
        store.dispatch('GET_INVOICE_PARTIAL', subscriptionId)
        store.dispatch('set_invoice_list', {
          customerId,
          subscriptionId,
          query
        })
        next()
      },
      component: () =>
        import(
          /* webpackChunkName: "invoices" */ '@/views/MyPlan/Invoice/History.vue'
        )
    }
  ]
}

export { routeMyPlan }
