import { DashInstance, CompanyTSInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

// STATE
// ---
export const state = {
  active: null,
  list: null,
  data: null,
  dataIsLoading: false,
  customer: null,
  isDelinquent: null,
  summary: null,
  settings: null,
  settingsIsLoading: false,
  statisticsAvailability: false,
  allowedIps: [],
  allowedIpsLoading: false
}

export const getters = {
  merchantActive (state) {
    return state.active ? state.active : false
  },
  merchantDelinquent (state) {
    return state.isDelinquent
  },
  merchantList (state) {
    return state.list ? state.list : false
  },
  merchantData (state) {
    return state.data ? state.data : false
  },
  merchantSettings (state) {
    return state.settings
  },
  merchantCustomerId (state) {
    if (state.customer) {
      return state.customer.customer_id
    } else {
      return false
    }
  },
  merchantSummary (state) {
    return state.summary
  },
  merchantDataIsLoading (state) {
    return state.dataIsLoading
  },
  merchantSettingsIsLoading (state) {
    return state.settingsIsLoading
  },
  statisticsAvailability (state) {
    return state.statisticsAvailability
  },
  allowedIps (state) {
    return state.allowedIps
  },
  allowedIpsLoading (state) {
    return state.allowedIpsLoading
  }
}

export const mutations = {
  setAllowedIps (state, allowedIps) {
    state.allowedIps = allowedIps
  },
  setAllowedIpsLoading (state, isLoading) {
    state.allowedIpsLoading = isLoading
  },
  setMerchantActive (state, merchant) {
    state.active = merchant
  },
  setMerchantDelinquent (state, isDelinquent) {
    state.isDelinquent = isDelinquent
  },
  setAvailability (state, availability) {
    state.statisticsAvailability = availability
  },
  setMerchantList (state, merchants) {
    state.list = merchants
  },
  setMerchantData (state, merchant) {
    state.data = merchant
  },
  setMerchantSettings (state, merchant) {
    state.settings = merchant
  },
  setCustomer (state, customer) {
    state.customer = customer
  },
  setMerchantSummary (state, payload) {
    state.summary = payload
  },
  setMerchantDataLoading (state, payload) {
    state.dataIsLoading = payload
  },
  setMerchantSettingsLoading (state, payload) {
    state.settingsIsLoading = payload
  }
}

export const actions = {
  SET_MERCHANT_ACTIVE ({ commit }, merchant) {
    commit('setMerchantActive', merchant)
    commit('toggleGlobalLoading', false, { root: true })
  },
  /**
   * Get details of a merchant (return data), except when user is Staff type (return 403)
   * @param {String} merchantId
   * @param {Boolean} refresh
   * @returns details of a merchant by id
   */
  GET_MERCHANT ({ commit, dispatch }, { merchantId, refresh }) {
    commit('setMerchantDataLoading', true)
    if (refresh) commit('setMerchantData', null)
    return CompanyTSInstance.merchant
      .getById(merchantId)
      .then(response => {
        commit('setMerchantData', response)
        commit('setMerchantDataLoading', false)
        return response
      })
      .catch(err => {
        catchError(err, { commit, dispatch })
        return commit('setMerchantDataLoading', false)
      })
  },
  GET_MERCHANT_SETTINGS ({ commit, dispatch }, { merchantId, refresh }) {
    commit('setMerchantSettingsLoading', true)
    if (refresh) commit('setMerchantSettings', null)
    return DashInstance.merchant
      .getById(merchantId)
      .then(response => {
        commit('setMerchantSettings', response)
        commit('setMerchantSettingsLoading', false)
        return response
      })
      .catch(err => {
        commit('setMerchantSettingsLoading', false)
        return catchError(err, { commit, dispatch })
      })
  },
  UPDATE_MERCHANT ({ commit, dispatch }, { merchantId, body }) {
    return CompanyTSInstance.merchant
      .update(merchantId, body)
      .then(res => res)
      .catch(err => catchError(err, { commit, dispatch }))
  },
  UPDATE_MERCHANT_SETTINGS ({ commit, dispatch }, { merchantId, body }) {
    commit('setMerchantSettingsLoading', true)
    return DashInstance.merchant
      .updateSettings(merchantId, body)
      .then(res => {
        commit('setMerchantSettingsLoading', false)
        return res
      })
      .catch(err => {
        commit('setMerchantSettingsLoading', false)
        return catchError(err, { commit, dispatch })
      })
  },
  GET_MERCHANT_SUMMARY ({ commit, dispatch }, merchantId) {
    return CompanyTSInstance.merchant
      .getSummary(merchantId, 'company', process.env)
      .then(res => {
        commit('setMerchantSummary', res)
        commit('setMerchantDelinquent', res.is_delinquent)
        commit('setAvailability', res.has_conciliation)
        dispatch('UPDATE_WHITELABEL', res.whitelabel)
        if (res.whitelabel.enabled)
          dispatch('LOAD_EMERALD_BY_THEME', res.whitelabel.theme)
        return Promise.resolve(res)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_ALLOWED_IPS ({ commit, dispatch }, merchantId) {
    commit('setAllowedIpsLoading', true)
    return CompanyTSInstance.merchant
      .getAllowedIps(merchantId)
      .then(response => {
        commit('setAllowedIps', response.allowed_ips)
        return Promise.resolve(response)
      })
      .catch(err => catchError(err, { commit, dispatch }))
      .finally(() => {
        commit('setAllowedIpsLoading', false)
      })
  },
  UPDATE_ALLOWED_IPS (_, { merchantId, body }) {
    return CompanyTSInstance.merchant
      .updateAllowedIps(merchantId, body)
      .then(Promise.resolve)
      .catch(Promise.reject)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
