export const STATUS_ACCOUNT = {
  inactive: {
    label: 'status.inactive',
    status: ['inactive'],
    style: '_status_inactive'
  },
  blocked: {
    label: 'status.blocked',
    status: ['blocked'],
    style: '_status_blocked'
  },
  pending: {
    label: 'status.pending',
    status: ['pending'],
    style: '_status_pending'
  },
  active: {
    label: 'status.active',
    status: ['active'],
    style: '_status_active'
  },
  test: {
    label: 'status.test',
    status: ['test'],
    style: '_status_test'
  }
}
