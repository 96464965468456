import { isEmptyObject } from '@/support/helpers.js'
import store from '@/store'

const routeAccounts = {
  path: 'accounts/',
  component: () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Account.vue'),
  props: {
    tabbarItems: [
      {
        route: 'accountProduction'
      },
      {
        route: 'accountTest'
      }
    ]
  },
  children: [
    {
      path: '',
      redirect: { name: 'accountProduction' }
    },
    {
      path: 'list/',
      redirect: { name: 'accountProduction' }
    },
    {
      path: 'production/',
      name: 'accountProduction',
      component: () =>
        import(
          /* webpackChunkName: "accountList" */ '@/views/Account/AccountList.vue'
        ),
      beforeEnter: (to, from, next) => {
        const query = isEmptyObject(to.query) ? { page: 1 } : to.query
        query.type = 'production'
        const merchantId = store.getters.merchantActive.id
        const merchantDelinquent = store.getters.merchantDelinquent
        if (!merchantDelinquent || store.getters.isEmployee)
          store.dispatch('GET_ACCOUNTS', { merchantId, query })
        next()
      },
      props: {
        accountType: 'production'
      }
    },
    {
      path: 'test/',
      name: 'accountTest',
      component: () =>
        import(
          /* webpackChunkName: "accountList" */ '@/views/Account/AccountList.vue'
        ),
      beforeEnter: (to, from, next) => {
        const query = isEmptyObject(to.query) ? { page: 1 } : to.query
        query.type = 'test'
        const merchantId = store.getters.merchantActive.id
        const merchantDelinquent = store.getters.merchantDelinquent
        if (!merchantDelinquent || store.getters.isEmployee)
          store.dispatch('GET_ACCOUNTS', { merchantId, query })
        next()
      },
      props: {
        accountType: 'test'
      }
    },
    {
      path: 'create/',
      name: 'createProductionAccount',
      component: () =>
        import(
          /* webpackChunkName: "createAccount" */ '@/views/Account/CreateAccount.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.permissionIsEnabled('account:create')) next()
        else store.commit('toggleGlobalError', { status: true, code: 404 })
      }
    },
    {
      path: 'create-test/',
      name: 'createTestAccount',
      component: () =>
        import(
          /* webpackChunkName: "createAccount" */ '@/views/Account/CreateTestAccount.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.permissionIsEnabled('account.test:create')) next()
        else store.commit('toggleGlobalError', { status: true, code: 404 })
      }
    },
    {
      path: '*',
      name: 'accountList',
      redirect: { name: 'accountProduction' }
    }
  ]
}
export { routeAccounts }
