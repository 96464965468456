import components from '@/utils/components'
const roles = {
  state: {
    components: components
  },
  getters: {
    permissionIsEnabled: (state, getters) => action => {
      return getters.profile.actions.includes(action)
    },
    componentIsEnabled: (state, getters) => (section, component) => {
      const theme = getters.whitelabel.theme.toLowerCase()
      return state.components[section][component]?.includes(theme)
    }
  }
}
export default roles
