import { CompanyTSInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

export const state = () => ({
  list: null,
  partial: null,
  detail: null,
  isLoaded: false
})

export const getters = {
  invoiceList: state => {
    return state.list ? state.list : false
  },
  invoicePartial: state => {
    return state.partial ? state.partial : false
  },
  invoiceDetail: state => {
    return state.detail ? state.detail : false
  },
  invoiceIsLoaded: state => {
    return state.isLoaded
  }
}

export const mutations = {
  setInvoiceList (state, invoices) {
    state.list = invoices
  },
  setInvoicePartial (state, invoices) {
    state.partial = invoices
  },
  setInvoiceDetail (state, invoice) {
    state.detail = invoice
  },
  setInvoiceLoadState (state, payload) {
    state.isLoaded = payload
  }
}

export const actions = {
  GET_INVOICES ({ commit, dispatch }, { merchantId, query }) {
    commit('setInvoiceLoadState', false)
    commit('setInvoiceList', null)
    return CompanyTSInstance.invoice
      .getAll(merchantId, query)
      .then(response => {
        commit('setInvoiceList', response)
        commit('setInvoiceLoadState', true)
        return response
      })
      .catch(err => {
        commit('setInvoiceLoadState', true)
        return { err, commit, dispatch }
      })
  },
  // GET_INVOICE_PARTIAL ({ commit, dispatch }, subscriptionId) {
  //   commit('setInvoicePartial', null)
  //   return CompanyInstance.invoice.getPartial(subscriptionId)
  //     .then(response => {
  //       commit('setInvoicePartial', response)
  //     })
  //     .catch(err => catchError(err, { commit, dispatch }))
  // },
  GET_INVOICE ({ commit, dispatch }, { merchantId, invoiceId }) {
    commit('setInvoiceDetail', null)
    return CompanyTSInstance.invoice
      .getById(merchantId, invoiceId)
      .then(response => {
        commit('setInvoiceDetail', response)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
