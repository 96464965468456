const whitelabel = {
  state: {
    whitelabel: {
      enabled: false,
      theme: 'pagarme',
      url: process.env.VUE_APP_BASE_URL,
      domain: '.pagar.me'
    }
  },
  getters: {
    whitelabel (state) {
      return state.whitelabel
    }
  },
  mutations: {
    updateWhitelabel (state, value) {
      state.whitelabel = value
    },
    updateWhitelabelTheme (state, value) {
      state.whitelabel.theme = value
    }
  },
  actions: {
    UPDATE_WHITELABEL ({ commit }, value) {
      commit('updateWhitelabel', value)
    },
    LOAD_EMERALD_BY_THEME ({ dispatch, commit }, theme) {
      const skin = ['jpmorgan'].includes(theme) ? 'jpmorgan' : 'pagarme'

      const emerald = () =>
        import(`emerald-workbench/dist/emerald.${skin}.min.css`)
      commit('updateWhitelabelTheme', theme)
      if (['pagarme', 'jpmorgan'].includes(theme)) dispatch('FORCE_LIGHT_MODE')
      emerald()
    }
  }
}
export default whitelabel
